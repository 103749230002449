@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    /* Slanted  */
    @font-face {
        font-family: "PPObjectSans-Slanted";
        font-weight: 800;
        src: url("../fonts/PPObjectSans-HeavySlanted.woff2") format("woff2");
    }
    @font-face {
        font-family: "PPObjectSans-Slanted";
        font-weight: normal;
        src: url("../fonts/PPObjectSans-MediumSlanted.woff2") format("woff2");
    }

    @font-face {
        font-family: "PPObjectSans";
        font-weight: 800;
        src: url("../fonts/PPObjectSans-Heavy.woff2") format("woff2");
    }
    @font-face {
        font-family: "PPObjectSans";
        font-weight: 700;
        src: url("../fonts/PPObjectSans-Bold.woff2") format("woff2");
    }
    @font-face {
        font-family: "PPObjectSans";
        font-weight: 400;
        src: url("../fonts/PPObjectSans-Medium.woff2") format("woff2");
    }
    html {
        color: "#535D62";
    }
}
@layer utilities {
    .cutter-big {
        clip-path: polygon(
            calc(0% + 50px) 0%,
            100% 0%,
            100% calc(100% - 50px),
            calc(100% - 50px) 100%,
            0% 100%,
            0% calc(0% + 50px)
        );
    }
    .cutter-small {
        clip-path: polygon(
            calc(0% + 25px) 0%,
            100% 0%,
            100% calc(100% - 25px),
            calc(100% - 25px) 100%,
            0% 100%,
            0% calc(0% + 25px)
        );
    }
}
